import { useNavigate } from "react-router-dom";
import { isOnMobile, trackLog } from "../utils/utils";
import { useEffect, useState } from "react";
import { HttpClient } from "../network";
import { toast } from "react-toastify";
import { Response } from "../network/types";

const clientId = '66682165529-82dqtrs4f1fhm12341l37q92b2kqlc7t.apps.googleusercontent.com'
const redirectPath = '/youtube/callback'
const scope = "https://www.googleapis.com/auth/youtube.readonly";
const rtmpBaseUrl = 'rtmp://a.rtmp.youtube.com/live2/'

export function youtubeRtmpAddress() {
    return rtmpBaseUrl + localStorage.getItem('youtubeStreamApiToken')
}

export function handleRequestYoutubeToken() {
    localStorage.setItem('lastPath', window.location.pathname)
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=${encodeURIComponent(scope)}&response_type=token&client_id=${clientId}&redirect_uri=${encodeURIComponent(window.location.origin + redirectPath)}`
    trackLog('authUrl', authUrl)
    window.location.href = authUrl
}

function Redirecting() {
    const [status, setStatue] = useState('Parsing access_token')
    const navigate = useNavigate()
    useEffect(() => {
        const hash = window.location.hash.substring(1);
        const params = new URLSearchParams(hash);
        const access_token = params.get('access_token');
        trackLog('access_token', access_token)

        if (typeof access_token != 'string' || access_token.length < 5) {
            trackLog('no access_token')
            setStatue('no access_token')
            // toast.error("not valid access_token");
            return
        }
        setStatue('Verifying token')
        const verify_token = async () => {

            trackLog('verifying token ~~~~~~~~~~~~~~~~')
            try {
                let response = await HttpClient.post<Response<any>>('user/update_youtube_api_token', {
                    youtubeOauthToken: access_token
                })
                setStatue('Verified! Redirecting..')
                localStorage.setItem('youtubeStreamApiToken', response.data.youtubeStreamApiToken)
                trackLog('youtubeStreamApiToken', response.data.youtubeStreamApiToken)
                let lastPath = localStorage.getItem('lastPath')
                if (lastPath) {
                    localStorage.removeItem('lastPath')
                    navigate(lastPath ?? '/')
                } else {
                    navigate('/')
                }
            } catch (e: any) {
                toast.error(e.message ?? "Failed to link YouTube. Contact the team for support.");
            }

        }
        verify_token()
    }, [])

    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="spinner"></div>
        <div style={{ fontSize: '1.2rem' }}>{status}</div>
    </div>
}


export function YoutubeLinkBtn(props: { fromWallet?: boolean }) {
    const [isLinked, setIsLinked] = useState(localStorage.getItem('youtubeStreamApiToken') ? true : false)
    const [isSending, setIsSending] = useState(false)
    const handleUnlink = async () => {
        setIsSending(true)
        try {
            let response = await HttpClient.post<Response<any>>('user/update_youtube_api_token', {
                youtubeOauthToken: ''
            })
            if (response?.data?.youtubeStreamApiToken) {
                toast.error("Failed to unlink Youtube")
                return
            }
            localStorage.removeItem('youtubeStreamApiToken')
            setIsLinked(false)
        } catch (e: any) {
            trackLog('unlink err', e)
            toast.error(e.message ?? "Failed to unlink Youtube. Try again");
        }
        setIsSending(false)
    }
    if (isLinked) {
        if (props.fromWallet) {
            return <div>You will stream here and Youtube simultanously. (
                {isSending ? <span>Unlinking..</span> : <span onClick={handleUnlink} style={{ cursor: 'pointer', color: 'rgb(0, 186, 250)', textDecoration: 'underline' }}>Unlink Youtube</span>}
                )</div>
        }
        return <></>

    }
    return <div> {isOnMobile() ? 'Stream to Youtube simultanously? ' : 'Want to stream here and Youtube simultanously? '}(
        <span onClick={() => handleRequestYoutubeToken()} style={{ cursor: 'pointer', color: 'rgb(0, 186, 250)', textDecoration: 'underline' }}>Link Youtube</span>
        )</div>
}

export const YoutubeCallback = <div style={{ height: 600, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
    <Redirecting />
</div>