import { useEffect, useRef } from "react";
import { trackLog } from "../utils/utils";

export type AudioChatMessage = {
    createdAt: number
    audioUrl: string
}

export function BotSpeech(props: { audioMsgs: AudioChatMessage[], commentatorDisplayName: string, commentatorProfilePicUrl: string, setAudioMessages: (f: any) => void }) {
    if (!props.commentatorDisplayName) {
        return null
    }
    const { audioMsgs } = props
    let borderWidth = '0px'
    let speech = null
    if (audioMsgs.length) {
        borderWidth = '5px'
        speech = <BotSpeechUrl audioUrl={audioMsgs[0].audioUrl} setAudioMessages={props.setAudioMessages} />
    }
    return audioMsgs.length > 0 ? <div style={{ display: 'flex', padding: '5px', width: '100%', justifyContent: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <img src={props.commentatorProfilePicUrl} style={{ width: '30px', height: '30px', borderRadius: '25px', borderColor: '#00ff44', borderWidth, borderStyle: 'solid' }} />
            <div>{props.commentatorDisplayName}  is commentating...</div>
            {speech}
        </div>
    </div> : null
}

export function BotSpeechUrl(props: { audioUrl: string, setAudioMessages: (f: any) => void }) {
    const { audioUrl } = props
    const audioRef = useRef<HTMLAudioElement | null>(null);
    // play audio
    useEffect(() => {
        if (!audioUrl) return;
        if (audioUrl) {
            trackLog('start playing ', audioUrl)

            if (audioRef.current) {
                audioRef.current.pause()
                audioRef.current.currentTime = 0
            }

            const audio = new Audio(audioUrl)
            audioRef.current = audio;
            audio.play().catch((error) => {
                trackLog("failed play video", error)
            });
            audio.addEventListener('ended', () => {
                props.setAudioMessages((prevMessages: any) => {
                    if (!prevMessages.length) {
                        return []
                    }
                    trackLog('remove finished speech')
                    return prevMessages.slice(1)
                });
            })
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.pause()
                audioRef.current.currentTime = 0
                audioRef.current = null
            }
        };
    }, [audioUrl])
    return <></>
}