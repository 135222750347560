
import { ChangeEvent, useState } from "react"
import { ComputeBudgetProgram, PublicKey, Transaction } from '@solana/web3.js'
import type { TokenInfo } from "../model/types"
import { trackLog } from "../utils/utils"
import './airdropPopup.css'
import { toast } from "react-toastify"
import { IN_APP_USD_FAKE_ADDRESS } from "./streamView"
import { createAssociatedTokenAccountInstruction, createTransferInstruction, getAccount, getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from "@solana/spl-token"
import { SolanaWallet } from "@web3auth/solana-provider"
import { FEE_PAYER_AND_DEPOSIT_ADDRESS } from "../wallet/web3auth"
import { createMemoInstruction } from "@solana/spl-memo"
import { HttpClient } from "../network"
import { Response } from "../network/types"
export function AirdropPopup(props: {
    connection: any
    web3auth: any
    userAddress: string
    handleClose: Function
    isLoading: boolean
    roomId: string
    tokenInfoList: {
        [key: string]: TokenInfo
    }
    handlePaymentError: (e: any) => {}
}) {
    return <>
        <div className="overlay" onClick={() => {
            props.handleClose()
        }} />
        <div className="popup">

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ fontSize: '0.9rem' }}>Airdrop any coin in your wallet to audiences</div>
                <img onClick={() => {
                    props.handleClose()
                }} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
            </div>

            {
                props.isLoading ?
                    <div className="leaderboardLoading">Loading...</div> :
                    <GiftPopupBody connection={props.connection} web3auth={props.web3auth}
                        userAddress={props.userAddress} tokenInfoList={props.tokenInfoList} roomId={props.roomId}
                        handleClose={props.handleClose}
                        handlePaymentError={props.handlePaymentError}
                    />
            }
        </div>
    </>
}
function GiftPopupBody(props: {
    connection: any
    web3auth: any
    userAddress: string
    roomId: string
    tokenInfoList: { [key: string]: TokenInfo }
    handleClose: Function
    streamCommentatorId?: string
    streamCreatorId?: string
    handlePaymentError: (e: any) => {}
}) {
    let connection = props.connection
    let web3auth = props.web3auth
    let roomId = props.roomId
    let defaultTokenKey = Object.keys(props.tokenInfoList)[0]
    let handleClose = props.handleClose
    let userAddress = props.userAddress
    const [amount, setAmount] = useState('')
    const [selectedToken, setSelectedToken] = useState(props.tokenInfoList[defaultTokenKey])
    const [isSelecting, setIsSelecting] = useState(false)
    const [isTipping, setIsTipping] = useState(false)
    const [balance, setBalance] = useState(props.tokenInfoList[defaultTokenKey].balance)
    const uiBalance = (Math.floor(parseFloat(selectedToken.uiBalance ?? '0') * 1000) / 1000).toFixed(3)

    const sendAirdrop = async () => {
        if (parseFloat(amount) < 1) {
            toast.error(`you must airdrop at least 1 ${(selectedToken.address === IN_APP_USD_FAKE_ADDRESS) ? "USD" : selectedToken.symbol}`)
            return
        }

        setIsTipping(true)

        try {
            const senderPublicKey = new PublicKey(userAddress);
            const feePayerPublicKey = new PublicKey(FEE_PAYER_AND_DEPOSIT_ADDRESS);

            const tokenMintAddress = new PublicKey(selectedToken.address!)

            // Get the associated token addresses for sender and recipient
            const senderTokenAccountAddress = await getAssociatedTokenAddress(
                tokenMintAddress,
                senderPublicKey);

            const recipientTokenAccountAddress = await getAssociatedTokenAddress(
                tokenMintAddress,
                feePayerPublicKey
            )
            const sendAmount = Math.floor(parseFloat(amount) * Math.pow(10, selectedToken.decimals ?? 1))
            if (sendAmount > parseFloat(selectedToken.balance ?? "0")) {
                toast.error(`Can't airdrop more than your balance (${selectedToken.uiBalance} ${selectedToken.symbol}).`)
                setIsTipping(false)
                return
            }

            const transaction = new Transaction()
            transaction.add(
                ComputeBudgetProgram.setComputeUnitPrice({
                    microLamports: 2000000,
                })
            )
            try {
                await getAccount(connection, recipientTokenAccountAddress);
            } catch (e) {
                console.error(e)
                transaction.add(
                    createAssociatedTokenAccountInstruction(
                        feePayerPublicKey,
                        recipientTokenAccountAddress,
                        feePayerPublicKey,
                        tokenMintAddress
                    )
                )
            }

            // Create a transaction instruction to transfer USDC
            const transferInstruction = await createTransferInstruction(
                senderTokenAccountAddress,
                recipientTokenAccountAddress,
                senderPublicKey,
                sendAmount, // Assuming USDC has 6 decimal places
                [],
                TOKEN_PROGRAM_ID
            );

            // Create a new transaction
            transaction.add(transferInstruction);

            transaction.add(
                createMemoInstruction(JSON.stringify({
                    "roomId": roomId
                }), [feePayerPublicKey]),
            );

            const { blockhash } = await connection.getLatestBlockhash();
            // Set the recent blockhash and the fee payer (sender)
            transaction.recentBlockhash = blockhash;
            transaction.feePayer = new PublicKey(FEE_PAYER_AND_DEPOSIT_ADDRESS);

            const solanaWallet = new SolanaWallet(web3auth!.provider!);
            // Sign the transaction
            const signedTransaction = await solanaWallet.signTransaction(transaction);
            // Serialize the transaction and send it to your backend
            const serializedTransaction = signedTransaction.serialize({ requireAllSignatures: false }).toString('base64');

            HttpClient.post<Response<any>>('room/airdrop/create', {
                roomId: props.roomId,
                signedTx: serializedTransaction,
                coinAddress: selectedToken.address,
                coinLogo: selectedToken.logo,
                coinSymbol: selectedToken.symbol,
                coinDecimals: selectedToken.decimals,
                amount: sendAmount,
            })
                .then((response) => {
                    setIsTipping(false)
                    toast.success("Airdop will start once it's confirmed on-chain")
                    handleClose()
                })
                .catch((e): any => {
                    handleClose()
                    props.handlePaymentError(e)
                    console.log("!!!!failed to gift", { e });
                    toast.error(e.response?.data?.errorMessage ?? "Failed to gift. Try again.");
                })
        } catch (e) {
            setIsTipping(false)
            toast.error("failed to airdrop. (" + e + ")")
            console.error(e)
        }

        trackLog('Sending airdrop', { amount, balance, selectedToken })
    }

    const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>): void => {
        setAmount(e.target.value);
    };

    const handleMax = () => {
        setAmount(uiBalance)
    }

    const handleOpenSelecting = () => {
        setIsSelecting(true)
    }

    const handleSelect = (token: TokenInfo) => {
        setBalance(token.balance ?? '0')
        setSelectedToken(token)
        setIsSelecting(false)
    }

    return (
        isSelecting ?
            // selecting wallet
            <SelectOptions tokenInfoList={props.tokenInfoList} handleSelect={handleSelect} /> :
            // main body
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ flex: 3 }}><input value={amount} style={{ width: '90%', fontSize: '1.7rem', padding: '5px 0 5px 10px' }} onChange={handleChangeAmount} placeholder="0" /></div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center', cursor: 'pointer', border: '1px solid var(--color-border)', borderRadius: '20px', padding: '5px ', fontSize: '0.8em' }} onClick={handleOpenSelecting} className='walletSelectionBtn'>
                        <img src={selectedToken.logo} style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                        <div>{selectedToken.symbol}</div>
                        <svg height="20" width="20" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z" /></svg>
                    </div>
                </div>
                <div style={{ fontSize: '0.9rem', display: 'flex', justifyContent: 'flex-end', gap: 5 }}>
                    <div style={{ color: 'var(--color-light-2)' }}>{uiBalance} {selectedToken.symbol}</div>
                    <div style={{ color: 'var(--color-btn)', cursor: 'pointer' }} onClick={handleMax}>Max</div>
                </div>
                <div className="btn" style={{ display: 'flex', justifyContent: 'center', fontSize: '1.2rem', padding: '0.8rem', marginTop: '20px' }} onClick={sendAirdrop}>{isTipping ? "Airdropping..." : "Airdrop"}</div>
            </div>
    )
}
function SelectOptions(props: {
    tokenInfoList: { [key: string]: TokenInfo }
    handleSelect: (token: TokenInfo) => void
}) {
    let tokenKeys = Object.keys(props.tokenInfoList)
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
        {tokenKeys.map(key => {
            let wallet = props.tokenInfoList[key]
            return <div key={key} onClick={() => {
                props.handleSelect(wallet)
            }} className='walletOption'>
                <img src={wallet.logo} style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <div style={{ fontSize: '1.2rem' }}>{wallet.name}</div>
                    <div style={{ fontSize: '0.9rem', color: 'var(--color-light-2)' }}>{wallet.symbol}</div>
                </div>
            </div>
        })}
    </div>
}