import { useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation, useNavigate, Navigate, useSearchParams } from "react-router-dom";
import { Maybe, UserAuthInfo } from "@web3auth/base";
import "./App.css";
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid'
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip'
import { ZIM, ZIMLoginConfig, ZIMSDK } from 'zego-zim-web';
// import RPC from "./ethersRPC"; // for using ethers.js

// Providers
import { SolanaPrivateKeyProvider, SolanaWallet } from "@web3auth/solana-provider";

// Adapters
import { GUEST_DISPLAY_NAME_KEY, GUEST_ID_KEY, HttpClient, REFERER_ID_KEY, USER_TOKEN_KEY } from "./network";
import { BuyCoinResponse, CreateBetOrStreamResponse, LoginResponse, PresignUrlResponse, Response, RoomsReponse, RoomsReponseV2, SelectedChannel, TopUserResponse, TopUsersResponse, UnresolvedBets, UpdateUserDisplayNameResponse, ZIMTokenResponse } from "./network/types";
import mixpanel from 'mixpanel-browser';
import StreamView, { getStreamId, getStreamType } from "./components/streamView";
import { StreamFramePreview } from "./components/streamFrame";
import { RANDOM_USER_DISPLAY_NAMES, isUserLoggedIn, markLogIn } from "./utils/login";
import { Stream, StreamCreationMode, UnresolvedBet } from "./model/types";
import { Connection, Transaction, PublicKey, ComputeBudgetProgram } from '@solana/web3.js';
import { createTransferInstruction, getAccount, getAssociatedTokenAddress, ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import axios from "axios";
import { formatTimestamp, getExternalStreamLink } from "./utils/stream";
import { getFormattedDate, getFormattedTime } from "./utils/time";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import { isOnMobile, isPwa, trackLog } from "./utils/utils";
import { askPwaView } from "./components/askPwa";

import { web3auth, web3authInit, RPC_URL, USDCAddress, FEE_PAYER_AND_DEPOSIT_ADDRESS, connectionType } from "./wallet/web3auth";
import { DepositPopup } from "./components/depositPopup";
import { TwitchCallback, TwitchLinkBtn } from "./components/twitch";
import { YoutubeCallback, YoutubeLinkBtn } from "./components/youtube";

const connection = new Connection(RPC_URL, 'confirmed');
const USDC_RENT_AMOUNT = 0.02

const ZEGO_APP_ID = 1133890363
const getZIM = () => ZIM;
getZIM().create({
  appID: ZEGO_APP_ID,
});
const zim: ZIMSDK = getZIM().getInstance();

const zegoExpressEngine = new ZegoExpressEngine(ZEGO_APP_ID, "wss://accesshub-wss.zego.im/accesshub")
zegoExpressEngine.setLogConfig({ logLevel: "warn" })

const HAS_SEEN_TUTORIAL_POPUP_KEY = 'HAS_SEEN_APP_TUTORIAL_POPUP_KEY'
const DEFAULT_PROFILE_URL = "/default_pfp.jpg"
type TimeZone = 'local' | 'utc'

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isIMLoggedIn, setIsIMLoggedIn] = useState(false);
  const [isResolver, setIsResolver] = useState(false);
  const [isCommunityMod, setIsCommunityMod] = useState(false);
  const [isShowingDisplayNamePopup, setIsShowingDisplayNamePopup] = useState(false);
  const [newDisplayName, setNewDisplayName] = useState("");
  const [remotePfpUrl, setRemotePfpUrl] = useState(DEFAULT_PROFILE_URL);
  // local pfp for upload when updating pfp
  const [profilePicUrl, setProfilePicUrl] = useState(DEFAULT_PROFILE_URL);
  const [profileFile, setProfileFile] = useState<File>();
  const [displayName, setDisplayName] = useState("");
  const [guestId, setGuestId] = useState("");
  const [guestDisplayName, setGuestDisplayName] = useState("");
  const [refererId, setRefererId] = useState("");
  const [userCoins, setUserCoins] = useState<number>(0);
  const [userPoints, setUserPoints] = useState<number>(0);
  const [streamCreationCost, setStreamCreationCost] = useState<number>(0);
  const [betStreamCreatorOrCommentatorTakeRate, setBetStreamCreatorOrCommentatorTakeRate] = useState<number>(0);
  const [giftPrice, setGiftPrice] = useState<{ [key: string]: number }>({});
  const [betCreationCost, setBetCreationCost] = useState<number>(0);
  const [betCreationTakeRate, setBetCreationTakeRate] = useState<number>(0);
  const [commentatorTakeRate, setCommentatorTakeRate] = useState<number>(0);
  const [decisionCommentatorTakeRate, setDecisionCommentatorTakeRate] = useState<number>(0);
  const [overallTakeRate, setOverallTakeRate] = useState<number>(0);
  const [overallDecisionTakeRate, setOverallDecisionTakeRate] = useState<number>(0);
  const [usdcAmountToBuyCoins, setUsdcAmountToBuyCoins] = useState<number>(20);
  const [usdcAmountToBuyCoinsString, setUsdcAmountToBuyCoinsString] = useState<string>("20");
  const [coinsToSell, setCoinsToSell] = useState<number>(0);
  const [coinsAmountToBuy, setCoinsAmountToBuy] = useState<string>("...");
  const [ethSellFunded, setEthSellFunded] = useState<string>("0");
  const [userAddress, setUserAddress] = useState<string>("");
  const [userBalance, setUserBalance] = useState<string>("0");
  const [streams, setStreams] = useState<Stream[]>([]);
  const [futureStreams, setFutureStreams] = useState<Stream[]>([]);
  const [pastStreams, setPastStreams] = useState<Stream[]>([]);
  const [isShowingPastStream, setIsShowingPastStream] = useState(false);
  // const [topUsers, setTopUsers] = useState<TopUserResponse[]>();
  const [topPointUsers, setTopPointUsers] = useState<TopUserResponse[]>();
  const [unresolvedBets, setUnresolvedBets] = useState<UnresolvedBet[]>();
  const [streamCreationMode, setStreamCreationMode] = useState<StreamCreationMode>(StreamCreationMode.CLOSED);
  const [isShowingTutorialPopup, setIsShowingTutorialPopup] = useState(false);
  const [isShowingUSDCWithdrawPopup, setIsShowingUSDCWithdrawPopup] = useState(false);
  // const [isShowingLeaderboardPopup, setIsShowingLeaderboardPopup] = useState(false);
  const [isShowingPointLeaderboardPopup, setIsShowingPointLeaderboardPopup] = useState(false);
  const [isShowingUnresolvedBetPopup, setIsShowingUnresolvedBetPopup] = useState(false);
  const [isFetchingUnresolvedBetPopup, setIsFetchingUnresolvedBetPopup] = useState(false);
  const [isShowingReferPopup, setIsShowingReferPopup] = useState(false);
  const [isShowingUserSecret, setIsShowingUserSecret] = useState(false);
  const [userPrivateKey, setUserPrivateKey] = useState<string>("");
  const [inCreateInAppStreamTitle, setInCreateInAppStreamTitle] = useState<string>("");
  const [inCreateFeaturedStreamUrl, setInCreateFeaturedStreamUrl] = useState<string>("");
  const [inCreateFeaturedStreamTitle, setInCreateFeaturedStreamTitle] = useState<string>("");
  const [inCreateFeaturedStreamYear, setInCreateFeaturedStreamYear] = useState<number>(2024);
  const [inCreateFeaturedStreamMonth, setInCreateFeaturedStreamMonth] = useState<number>(1);
  const [inCreateFeaturedStreamDay, setInCreateFeaturedStreamDay] = useState<number>(1);
  const [inCreateFeaturedStreamHour, setInCreateFeaturedStreamHour] = useState<number>(1);
  const [inCreateFeaturedStreamMinute, setInCreateFeaturedStreamMinute] = useState<number>(1);
  const createStreamContainerRef = useRef<HTMLDivElement>(null);
  const [walletPageSendAddress, setWalletPageSendAddress] = useState("");
  const [zimToken, setZimToken] = useState("");
  const [walletPageSendAmount, setWalletPageSendAmount] = useState<number | undefined>(undefined);
  const [isSendingWalletETH, setIsSendingWalletETH] = useState(false);
  const [isSendingWalletUSDCOut, setIsSendingWalletUSDCOut] = useState(false);
  const [isSellingCoin, setIsSellingCoin] = useState(false);
  const [streamCountDowns, setStreamCountdowns] = useState<{ [key: string]: number }>({});
  const [timeZone, setTimeZone] = useState<TimeZone>('local' as TimeZone);
  const location = useLocation();
  const navigate = useNavigate()
  const [hasInteracted, setHasInteracted] = useState(false)
  const [web3LoggedIn, setWeb3LoggedIn] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const [isShowingDepositPopup, setIsShowingDepositPopup] = useState(false)
  const handlePaymentError = (e: any) => {
    trackLog('handlePaymentError called', e.status)
    if (e.status === 456) {
      trackLog('handlePaymentError called')
      setIsShowingDepositPopup(true)
      return true
    }
    return false
  }
  const handleInteraction = () => {
    setHasInteracted(true);
  };
  useEffect(() => {
    const reloadRooms = setInterval(() => {
      getRooms()
    }, 15 * 1000)
    return () => {
      clearInterval(reloadRooms);
    };
  }, [])
  // useEffect(() => {
  //   zegoExpressEngine.on('roomStreamUpdate', handleUpdateStreamsState)
  //   return () => {
  //     zegoExpressEngine.off('roomStreamUpdate', handleUpdateStreamsState)
  //   }
  // }, [])

  // handle web3login state change
  useEffect(() => {
    const handleWeb3LoginStateChage = async () => {
      trackLog('handleWeb3LoginStateChage called', web3LoggedIn, web3auth?.connected)
      if (!isInitialized) { // prevent guest->loggedIn flash
        return
      }
      if (web3LoggedIn) {
        trackLog('web3auth', web3auth)
        const token = localStorage.getItem(USER_TOKEN_KEY)
        try {
          var userInfo: any
          if (connectionType.isWallet()) {
            userInfo = await web3auth.authenticateUser()
          } else {
            userInfo = await web3auth.getUserInfo()
          }
          trackLog('userInfo', userInfo)
          localStorage.setItem(USER_TOKEN_KEY, userInfo.idToken!)
          await loginBackend(userInfo.name, userInfo.profileImage, userInfo.email);
          setLoggedIn(true)
          handleCoinsToBuyChange(usdcAmountToBuyCoins.toString())
        } catch (e) {
          trackLog(e)
        }

      } else {
        handleCoinsToBuyChange('0')
        setupGuest()
      }
    }
    handleWeb3LoginStateChage()
  }, [isInitialized, web3LoggedIn])


  useEffect(() => {
    if (!hasInteracted) {
      const handleUserInteraction = () => handleInteraction();

      window.addEventListener('click', handleUserInteraction, { once: true });
      window.addEventListener('touchstart', handleUserInteraction, { once: true });

      return () => {
        window.removeEventListener('click', handleUserInteraction);
        window.removeEventListener('touchstart', handleUserInteraction);
      };
    }
  }, [hasInteracted]);



  const handleCoinsToBuyChange = (newUsdcAmount: string) => {
    if ((newUsdcAmount === null) && (newUsdcAmount === '') && isNaN(Number(newUsdcAmount))) {
      return
    }

    let parsedAmount = 0
    if (newUsdcAmount.endsWith(".")) {
      parsedAmount = parseFloat(newUsdcAmount.substring(0, newUsdcAmount.length - 1)) || 0
    } else {
      parsedAmount = parseFloat(newUsdcAmount) || 0
    }

    const usdcNeeded = Math.max(parsedAmount - USDC_RENT_AMOUNT, 0)
    setCoinsAmountToBuy((Math.floor(usdcNeeded * 100) / 100).toFixed(2))
    setUsdcAmountToBuyCoins(usdcNeeded)
    setUsdcAmountToBuyCoinsString(newUsdcAmount)
  }

  const updateToMaxCoinBuyAmount = () => {
    // TODO: don't hardcode 0.02 rent
    const usdcNeeded = Math.max(parseFloat(userBalance) - USDC_RENT_AMOUNT, 0)
    const usdcAmountToBuyWithTwoDecimals = (Math.floor(usdcNeeded * 100) / 100).toFixed(2)
    setCoinsAmountToBuy(usdcAmountToBuyWithTwoDecimals)
    setUsdcAmountToBuyCoins(usdcNeeded)
    setUsdcAmountToBuyCoinsString(usdcNeeded.toFixed(1) === userBalance ?
      usdcNeeded.toFixed(1) : usdcAmountToBuyWithTwoDecimals)
  }

  const handleCoinsToSellChange = (newCoinSellAmount: number) => {
    setCoinsToSell(newCoinSellAmount)
    const ethFunded = newCoinSellAmount
    setEthSellFunded(ethFunded.toFixed(2))
  }

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };


  const setupGuest = async () => {
    const refererId = searchParams.get('referer')
    if (refererId && refererId !== null) {
      localStorage.setItem(REFERER_ID_KEY, refererId)
    }

    let guestId = localStorage.getItem(GUEST_ID_KEY)
    if (guestId === null || guestId.length === 0) {
      guestId = uuidv4()
      localStorage.setItem(GUEST_ID_KEY, guestId)
    }
    setGuestId(guestId)

    let guestDisplayName = localStorage.getItem(GUEST_DISPLAY_NAME_KEY)
    if (guestDisplayName === null || guestDisplayName.length === 0) {
      guestDisplayName = RANDOM_USER_DISPLAY_NAMES[Math.floor(Math.random() * RANDOM_USER_DISPLAY_NAMES.length)]
        + "" + Math.floor(Math.random() * 1000)
      localStorage.setItem(GUEST_DISPLAY_NAME_KEY, guestDisplayName)
    }
    setGuestDisplayName(guestDisplayName)

    await HttpClient.post<Response<ZIMTokenResponse>>('guest/chat_token', {
      guestUserId: guestId
    })
      .then((response) => {
        const data = response.data
        setZimToken(data.zimToken)
        loginIM(guestId!, data.zimToken)
        getRooms()
      })
      .catch(async (e) => {
        console.log("!!!!loginBackend guest", { e });
        toast.error("Failed to login as guest. Try again. (Reason: " + e.message + '"');
      })
  }

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [location]);

  const handleWeb3Connected = () => {
    connectionType.set(web3auth)
    setWeb3LoggedIn(true)
  }

  // this function should be executed only once
  const init = async () => {
    window.addEventListener('resize', handleResize);

    await web3authInit()
    if (web3auth.connected) {
      handleWeb3Connected()
    }

    const hasSeenTutorial = localStorage.getItem(HAS_SEEN_TUTORIAL_POPUP_KEY)
    if (!hasSeenTutorial || hasSeenTutorial !== 'true') {
      showTutorialPopup()
      localStorage.setItem(HAS_SEEN_TUTORIAL_POPUP_KEY, 'true')
    }
    setIsInitialized(true)
  }

  useEffect(() => {
    if (streamCreationMode === StreamCreationMode.CLOSED) {
      setInCreateInAppStreamTitle("")
      setInCreateFeaturedStreamUrl("")
      setInCreateFeaturedStreamTitle("")
    } else if (streamCreationMode === StreamCreationMode.FUTURE) {
      const now = Date.now()
      setInCreateFeaturedStreamYear(new Date(now).getFullYear())
      setInCreateFeaturedStreamMonth(new Date(now).getMonth() + 1)
      setInCreateFeaturedStreamDay(new Date(now).getDate())
      setInCreateFeaturedStreamHour(new Date(now).getHours())
      setInCreateFeaturedStreamMinute(new Date(now).getMinutes())
    }
  }, [streamCreationMode]);

  useEffect(() => {
    setNewDisplayName(displayName)
  }, [displayName]);

  useEffect(() => {
    setProfilePicUrl(remotePfpUrl)
  }, [remotePfpUrl])

  // useEffect(() => {
  //   handleCoinsToSellChange(coinsToSell)
  // }, [coinsToSell]);

  useEffect(() => {
    init();
  }, []);

  const updateUserCoinBalance = async () => {
    await HttpClient.get<Response<LoginResponse>>('user/coin_balance')
      .then((response) => {
        const data = response.data

        setUserCoins(data.coins)
        setUserPoints(data.points)
      })
      .catch((e) => {
        toast.error("Failed to refresh. Try again (Reason: " + e.message + '"');
      })
  }

  const getBalance = async (address: string): Promise<string> => {
    if (!address) {
      setUserBalance("0")
      return "0"
    }

    const usdcMintAddress = new PublicKey(USDCAddress);

    console.log("!!!!!", { address })

    const associatedTokenAddress = await getAssociatedTokenAddress(
      usdcMintAddress,
      new PublicKey(address),
      false,
      TOKEN_PROGRAM_ID,
      ASSOCIATED_TOKEN_PROGRAM_ID
    );

    // Fetch the account info for the associated token address
    const accountInfo = await connection.getAccountInfo(associatedTokenAddress);

    if (!accountInfo) {
      console.log('No USDC token account found for this public key.');
      return "0";
    }

    // Decode the account info to get the balance
    const tokenAccount = await getAccount(connection, associatedTokenAddress);

    // USDC has 6 decimal places
    const newBalance = (Number(tokenAccount.amount) / Math.pow(10, 6)).toFixed(2)
    setUserBalance(newBalance)
    return newBalance
  };

  const getRooms = async () => {
    trackLog('getRooms called')
    const response = await HttpClient.post<Response<RoomsReponseV2>>('rooms', { "apiVersion": 2 })
      .then((response) => {
        const data = response.data
        if (data.rooms) {
          const liveStreams = []
          const futureStreams = []

          for (const room of data.rooms) {
            const isFeaturedStream = room.streamStartTime && room.streamStartTime > 0
            if (!room.isStreamOver && (!isFeaturedStream || (room.newStreamId && room.newStreamId !== ""))) {
              liveStreams.push(room)
            } else {
              futureStreams.push(room)
            }
          }

          futureStreams.sort((streamA, streamB) => {
            if (streamA.streamStartTime != streamB.streamStartTime) {
              return streamA.streamStartTime! - streamB.streamStartTime!
            }

            return streamA.roomId.localeCompare(streamB.roomId)
          })

          setStreams(liveStreams.map((room) => {
            return {
              id: room.roomId,
              isStreamOver: room.isStreamOver,
              bettedTVL: room.bettedTVL,
              newStreamId: room.newStreamId,
              streamStartTime: room.streamStartTime,
              streamTitle: room.streamTitle,
              commentatorId: room.commentatorId,
              isStreamingNatively: room.isStreamingNatively,
              streamerProfilePicUrl: room.streamerProfilePicUrl,
              streamerDisplayName: room.streamerDisplayName,
              streamPreviewThumbnail: room.streamPreviewThumbnail
            } as Stream
          }))

          setFutureStreams(futureStreams.map((room) => {
            return {
              id: room.roomId,
              isStreamOver: room.isStreamOver,
              bettedTVL: room.bettedTVL,
              newStreamId: room.newStreamId,
              streamStartTime: room.streamStartTime,
              streamTitle: room.streamTitle,
              commentatorId: room.commentatorId,
              streamerProfilePicUrl: room.streamerProfilePicUrl,
              streamerDisplayName: room.streamerDisplayName,
              streamPreviewThumbnail: room.streamPreviewThumbnail
            } as Stream
          }))
        }

        if (data.pastRooms) {
          setPastStreams(data.pastRooms.map((room) => {
            return {
              id: room.roomId,
              isStreamOver: room.isStreamOver,
              bettedTVL: room.bettedTVL,
              newStreamId: room.newStreamId,
              streamStartTime: room.streamStartTime,
              streamTitle: room.streamTitle,
              commentatorId: room.commentatorId,
              streamerProfilePicUrl: room.streamerProfilePicUrl,
              streamerDisplayName: room.streamerDisplayName,
              isStreamingNatively: room.isStreamingNatively,
              streamPreviewThumbnail: room.streamPreviewThumbnail
            } as Stream
          }))
        }
      })
      .catch((e) => {
        console.log("!!!!getRooms failed", { e });
        toast.error("Failed to fetch streams. Try again (Reason: " + e.message + '"');
      })
  }

  const logFirstLoadIfLoggedIn = async () => {
    mixpanel.init('06afc031560e8420cc44def0765bf1de', { debug: true, track_pageview: true, persistence: 'localStorage' });
    mixpanel.track('APP_START', {})
  }

  const loginBackend = async (displayName?: string, profilePicUrl?: string, email?: string) => {
    const refererId: string | null = localStorage.getItem(REFERER_ID_KEY)
    const response = await HttpClient.post<Response<LoginResponse>>('user/login', {
      displayName: displayName,
      profilePicUrl: profilePicUrl,
      email: email,
      refererId: refererId === null ? undefined : refererId
    })
      .then((response) => {

        const data = response.data
        if (data.twitchStreamApiToken) {
          localStorage.setItem('twitchStreamApiToken', data.twitchStreamApiToken
          )
        }
        if (data.youtubeStreamApiToken) {
          localStorage.setItem('youtubeStreamApiToken', data.youtubeStreamApiToken
          )
        }
        setZimToken(data.zimToken)
        console.log('window.location.pathname', window.location.pathname);
        if (searchParams.get('referer')) {
          setSearchParams("")
        }
        updateLoginStatus(true)
        logFirstLoadIfLoggedIn()
        loginIM(data.address, data.zimToken)
        if (data.profilePicUrl && data.profilePicUrl !== "") {
          setRemotePfpUrl(data.profilePicUrl)
        }
        setRefererId(data.refererId ?? "")
        setDisplayName(data.displayName!)
        setUserCoins(data.coins)
        setUserPoints(data.points)
        setIsResolver(data.isResolver ?? false)
        setIsCommunityMod(data.isCommunityMod)
        setUserAddress(data.address)
        getBalance(data.address)
        setStreamCreationCost(data.streamCreationCost)
        setBetStreamCreatorOrCommentatorTakeRate(data.betStreamCreatorOrCommentatorTakeRate)
        if (data.giftPrice) {
          setGiftPrice(data.giftPrice)
        }
        setBetCreationCost(data.betCreationCost)
        setBetCreationTakeRate(data.betCreationTakeRate)
        setDecisionCommentatorTakeRate(data.decisionCommentatorTakeRate)
        setOverallTakeRate(data.overallTakeRate)
        setOverallDecisionTakeRate(data.overallDecisionTakeRate)
        getRooms()
        localStorage.removeItem(REFERER_ID_KEY)
      })
      .catch(async (e) => {
        console.log("!!!!loginBackend", { e });
        // await doLogOut()
        toast.error("Failed to login. Try again. (Reason: " + e.message + '"');
      })
  }

  const updateLoginStatus = (loggedIn: boolean) => {
    markLogIn(loggedIn)
    setLoggedIn(loggedIn)
  }

  const loginIM = async (address: string, zimToken: string) => {
    const shortAddress = address.substring(0, 32)
    console.log("loginout", { shortAddress, zimToken })
    zim.logout()
    console.log("loginIM", { shortAddress, zimToken })
    setIsIMLoggedIn(false)
    zim.login(shortAddress, {
      token: zimToken,
      isOfflineLogin: false
    } as ZIMLoginConfig)
      .then(() => {
        trackLog(address, ' logged in zim')
        console.log("Logged into zim", { shortAddress })
        setIsIMLoggedIn(true)
      })
      .catch((e: any) => {
        trackLog(address, ' failed to login', e)
        toast.error("Failed to connect. Try again.  (Reason: " + e.message + '"');
        console.log("error", e)
      })
  }

  const copyWalletAddress = async () => {
    await navigator.clipboard.writeText(userAddress);
    toast.success("Address copied!")
  }

  const login = async () => {
    if (!web3auth) {
      return
    }

    try {
      trackLog('trying login')
      const web3authProvider = await web3auth.connect();
      trackLog('web3authProvider', web3auth.connected, web3authProvider)
      if (web3auth.connected) {
        handleWeb3Connected()
      }
    } catch (e) {
      console.log(e)
    }
  };

  const logout = async () => {
    if (!web3auth.connected) {
      trackLog("web3auth not initialized yet");
      return;
    }

    await doLogOut()
  };

  const doLogOut = async () => {
    try {
      await zim.logout();
      await web3auth?.logout();
      await zegoExpressEngine.logoutRoom();
      setIsIMLoggedIn(false)
    } catch (e) {
      console.log(e);
    }

    setZimToken("")
    updateLoginStatus(false)
    setDisplayName("")
    setUserAddress("")
    setUserBalance("0")

    localStorage.clear()
    // don't show tutorial box after log out
    localStorage.setItem(HAS_SEEN_TUTORIAL_POPUP_KEY, 'true')

    window.location.href = '/'
  };

  const onPfpUpdated = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setProfilePicUrl(imageURL);
      setProfileFile(file);
    }
  }

  const createStream = () => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    setStreamCreationMode(StreamCreationMode.IN_APP)
  }

  const abortingCreatingStream = () => {
    setStreamCreationMode(StreamCreationMode.CLOSED)
  }

  const closeUpdateProfileInfoPopup = () => {
    setIsShowingDisplayNamePopup(false)
    setProfilePicUrl(remotePfpUrl)
  }

  const closeDepositPopup = () => {
    setIsShowingDepositPopup(false)
    setUsdcAmountToBuyCoins(20)
    setUsdcAmountToBuyCoinsString("20")
  }

  const updateDisplayNameAndPfpOnBackend = async () => {
    if (!newDisplayName || newDisplayName.length === 0 || newDisplayName.trim().length === 0) {
      toast.error("Display name can't be empty")
      return
    }

    if (!profilePicUrl || profilePicUrl === DEFAULT_PROFILE_URL || !profileFile) {
      HttpClient.post<Response<LoginResponse>>('user/profile_info', {
        displayName: newDisplayName,
        profilePicUrl: remotePfpUrl
      })
        .then((response) => {
          const data = response.data
          console.log(data)

          if (data.profilePicUrl && data.displayName) {
            setRemotePfpUrl(data.profilePicUrl)
            setDisplayName(data.displayName)
            closeUpdateProfileInfoPopup()
          } else {
            toast.error("Failed to update profile info. Try again");
          }
        })
        .catch((e) => {
          toast.error("Failed to update profile info. Try again (Reason: " + e.message + '"');
        })
      return
    }

    const fileName = "pfp/" + uuidv4() + profileFile.name
    await HttpClient.get<Response<PresignUrlResponse>>('user/getPresignUrl?fileName=' + fileName)
      .then((response) => {
        const data = response.data
        console.log(data)
        console.log("upload url " + data.upload_url)

        axios.put(data.upload_url, profileFile, {
          headers: {
            "Content-Type": profileFile.type
          }
        })
          .then((response) => {
            console.log(response.data)

            HttpClient.post<Response<LoginResponse>>('user/profile_info', {
              displayName: newDisplayName,
              profilePicUrl: data.upload_url.split("?")[0]
            })
              .then((response) => {
                const data = response.data
                console.log(data)

                if (data.profilePicUrl && data.displayName) {
                  setRemotePfpUrl(data.profilePicUrl)
                  setDisplayName(data.displayName)
                  closeUpdateProfileInfoPopup()
                } else {
                  toast.error("Failed to update profile info. Try again");
                }
              })
              .catch((e) => {
                toast.error("Failed to update profile info. Try again (Reason: " + e.message + '"');
              })
          })
          .catch((e) => {
            console.log(e)
            toast.error("Failed to upload profile pic (aws error). Try again (Reason: " + e.message + '"');
          })
      })
      .catch((e) => {
        toast.error("Failed to upload profile pic. Try again (Reason: " + e.message + '"');
      })
  }

  const capitalize = (s: string) => {
    if (s.toLocaleLowerCase() == "vs" || s.toLocaleLowerCase() == "v" || s.toLocaleLowerCase() == "vs.") {
      return "vs."
    }

    return s && s[0].toUpperCase() + s.slice(1)
  }

  const createStreamOnBackend = async () => {
    if (userCoins < streamCreationCost) {
      toast.error("Need " + streamCreationCost + " USD to create stream. Top up your account.")
      return
    }

    let inCreateStreamerUserName
    let inCreateChannelName
    let streamStartTime = 0
    let streamTitle
    if (streamCreationMode === StreamCreationMode.FUTURE) {
      if (!inCreateFeaturedStreamTitle || inCreateFeaturedStreamTitle.length === 0) {
        toast.error("Stream title can't be empty")
        return
      }

      try {
        if (timeZone === 'utc') {
          streamStartTime = new Date(Date.UTC(inCreateFeaturedStreamYear, inCreateFeaturedStreamMonth - 1, inCreateFeaturedStreamDay,
            inCreateFeaturedStreamHour, inCreateFeaturedStreamMinute)).getTime()
        } else {
          const localStreamStartDate = new Date(inCreateFeaturedStreamYear, inCreateFeaturedStreamMonth, inCreateFeaturedStreamDay, inCreateFeaturedStreamHour, inCreateFeaturedStreamMinute)
          const y = localStreamStartDate.getUTCFullYear()
          const m = localStreamStartDate.getUTCMonth()
          const d = localStreamStartDate.getUTCDate()
          const h = localStreamStartDate.getUTCHours()
          const min = localStreamStartDate.getUTCMinutes()
          streamStartTime = new Date(Date.UTC(y, m - 1, d, h, min)).getTime()
        }

        if (streamStartTime < Date.now()) {
          toast.error("Stream start time can only be in the future time")
          return
        }
      } catch (e: any) {
        toast.error("Invalid start time: " + e.message)
        return
      }

      streamTitle = inCreateFeaturedStreamTitle
      inCreateStreamerUserName = streamTitle.replaceAll(" ", "")
      inCreateChannelName = SelectedChannel.FEATURED
    } else if (streamCreationMode === StreamCreationMode.EXTERNAL_LINK) {
      if (!inCreateFeaturedStreamUrl || inCreateFeaturedStreamUrl.length === 0) {
        toast.error("Stream link can't be empty")
        return
      }

      const streamLink = getExternalStreamLink(inCreateFeaturedStreamUrl, toast)
      if (!streamLink.inCreateChannelName || !streamLink.inCreateStreamerUserName) {
        return
      }

      inCreateStreamerUserName = streamLink.inCreateStreamerUserName
      inCreateChannelName = streamLink.inCreateChannelName
    } else if (streamCreationMode === StreamCreationMode.IN_APP) {
      if (!inCreateInAppStreamTitle || inCreateInAppStreamTitle.length === 0) {
        toast.error("Stream title can't be empty")
        return
      }

      streamTitle = inCreateInAppStreamTitle
      inCreateStreamerUserName = streamTitle.replaceAll(" ", "")
      inCreateChannelName = SelectedChannel.IN_APP
    }

    await HttpClient.post<Response<CreateBetOrStreamResponse>>('room/create', {
      streamerUserName: inCreateStreamerUserName,
      channelName: inCreateChannelName,
      userCoins: userCoins,
      streamStartTime: streamStartTime,
      streamTitle: streamTitle
    })
      .then((response) => {
        const data = response.data

        setStreamCreationMode(StreamCreationMode.CLOSED)
        setUserCoins(data.userCoins)
        setUserPoints(data.userPoints)
        navigate("/stream/" + data.roomId)
      })
      .catch((e) => {
        console.log("!!!!create room", { e });
        toast.error("Failed to create room. Try again. (Reason: " + e.message + '"');
      })
  }

  const getStreamDateIfNecessary = (streamArray: Stream[], prevIndex: number, currentIndex: number): string | undefined => {
    const timezoneOffset = new Date().getTimezoneOffset()
    const currentStreamStartTime = streamArray[currentIndex].streamStartTime ?
      new Date(streamArray[currentIndex].streamStartTime! + timezoneOffset) : new Date()
    if (prevIndex < 0) {
      return getFormattedDate(currentStreamStartTime)
    }

    const prevStreamStartTime = streamArray[prevIndex].streamStartTime ?
      new Date(streamArray[prevIndex].streamStartTime! + timezoneOffset) : new Date()

    if (prevStreamStartTime.getFullYear() === currentStreamStartTime.getFullYear() &&
      prevStreamStartTime.getMonth() === currentStreamStartTime.getMonth() &&
      prevStreamStartTime.getDate() === currentStreamStartTime.getDate()) {
      return undefined
    }

    return getFormattedDate(currentStreamStartTime)
  }

  const getStreamPreview = (stream: Stream) => {
    return <div className="trendingStreamItem" key={stream.id}>
      <div className="previewStreamContainer" onClick={() => {
        navigate("/stream/" + stream.id + window.location.search)
        // window.location.href = '/stream/' + stream.id + window.location.search 
      }}>
        <div style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 999 }}></div>
        {
          getStreamType(getStreamId(stream)) === SelectedChannel.FEATURED ? (
            <div className="previewStreamPlayer featuredPreviewStreamPlayer">
              {
                (stream.streamTitle && stream.streamTitle !== "") && (
                  <div className="countdownTextContainer">{stream.streamTitle}</div>
                )
              }
              {
                getStreamId(stream) && (
                  <div className="streamCountdownText">
                    {formatTimestamp(streamCountDowns[getStreamId(stream)!])}
                  </div>
                )
              }
            </div>
          ) : <StreamFramePreview stream={stream} screenWidth={screenWidth} />

        }
        {
          stream.isStreamOver && !stream.isStreamingNatively && (
            <div className="streamPreviewLivenessOverlay">
              Offline
            </div>
          )
        }
        <div className="streamPreviewFooter">
          <div>Total Wagered:</div>
          <img className="streamPreviewCoinIcon" src="/coin.png"></img>
          <div>{stream.bettedTVL}</div>
        </div>
      </div>
    </div>
  }

  const landingView = (
    <div className="landingViewContainer">
      <div className="landingViewTopPanel">
        <div className="addStreamContainer" onClick={createStream}>
          <img className="addStreamIcon" src="/addIcon.svg" />
          <div className="addStreamText">Add Stream</div>
        </div>
      </div>
      {
        (streams.length > 0) && (
          <div className="trendingStreamsContainer">
            <div className="mostRecentStreamHeader liveStreamHeader">
              <div className="addStreamContainerText">
                Live Streams
              </div>
            </div>
            <div className="trendingStreams">
              {
                streams.map((stream) => (
                  getStreamPreview(stream)
                ))
              }
            </div>
          </div>
        )
      }

      <div className={streams.length === 0 ? "mostRecentStreamHeader liveStreamHeader" : "mostRecentStreamHeader"}>
        <div className="addStreamContainerText">
          Future Streams
        </div>
      </div>
      <div className="futureStreamsContainer">
        {
          (futureStreams.length > 0) ? (
            futureStreams.map((stream, index) => (
              <div className="futureStreamContainer">
                <div className="futureStreamDateHeader">
                  {getStreamDateIfNecessary(futureStreams, index - 1, index)}
                </div>
                <div className="futureStreamContentContainer" onClick={() => {
                  navigate("/stream/" + stream.id + window.location.search)
                  // window.location.href = '/stream/' + stream.id + window.location.search 
                }}>
                  <div className="futureStreamContent">
                    <div className="futureStreamContentTitle">{stream.streamTitle}</div>
                    <div className="futureStreamContentTime">{getFormattedTime(stream.streamStartTime!)} (Local Time)</div>
                  </div>
                  <div className="futureStreamContentCaret">{">"}</div>
                </div>
              </div>
            )
            )
          ) : (
            <div className="no-schedule-header">
              No scheduled stream. Check back later.
            </div>
          )
        }
      </div>

      {
        (pastStreams.length > 0) && (
          isShowingPastStream ? (
            <>
              <div className="mostRecentStreamHeader pastStreamHeader">
                <div className="addStreamContainerText">
                  Past Streams
                </div>
              </div>
              {
                <div className="trendingStreams">
                  {
                    pastStreams.map((stream) => (
                      getStreamPreview(stream)
                    ))
                  }
                </div>
              }
            </>
          ) : (
            <div className="showPastStreamLink" onClick={() => setIsShowingPastStream(true)}>
              {">> Show past streams"}
            </div>
          )
        )
      }
    </div>
  )

  const closeWithdrawPopup = () => {
    setIsShowingUSDCWithdrawPopup(false)
    setWalletPageSendAddress("")
    setWalletPageSendAmount(undefined)
  }

  const sendUSDCBalanceOut = async () => {
    if (!walletPageSendAmount || walletPageSendAmount <= 0) {
      toast.error("Enter a positive amount");
      return
    }

    if (!walletPageSendAddress || walletPageSendAddress === '') {
      toast.error("Enter a valid address");
      return
    }

    if (walletPageSendAmount < 10) {
      toast.error("Can only withdraw more than 10 USDC.");
      return
    }

    if (walletPageSendAmount > parseFloat(userBalance)) {
      toast.error("Not enough USDC balance. Lower your withdraw amount.");
      return
    }

    setIsSendingWalletUSDCOut(true)

    try {
      const senderPublicKey = new PublicKey(userAddress);
      const recipientPublicKey = new PublicKey(walletPageSendAddress);

      // Create a new token object for USDC (replace with the correct token mint address)
      const usdcMintAddress = new PublicKey(USDCAddress);

      // Get the associated token addresses for sender and recipient
      const senderTokenAccountAddress = await getAssociatedTokenAddress(
        usdcMintAddress,
        senderPublicKey);

      const recipientTokenAccountAddress = await getAssociatedTokenAddress(
        usdcMintAddress,
        recipientPublicKey);

      const usdcBuyAmount = Math.floor((walletPageSendAmount) * Math.pow(10, 6))
      console.log("!!!!!", { userAddress, userBalance, walletPageSendAmount, walletPageSendAddress })
      // Create a transaction instruction to transfer USDC
      const transferInstruction = createTransferInstruction(
        senderTokenAccountAddress,
        recipientTokenAccountAddress,
        senderPublicKey,
        usdcBuyAmount, // Assuming USDC has 6 decimal places
        [],
        TOKEN_PROGRAM_ID
      );

      // Create a new transaction
      const transaction = new Transaction().add(transferInstruction);
      const { blockhash } = await connection.getLatestBlockhash();
      // Set the recent blockhash and the fee payer (sender)
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = new PublicKey(FEE_PAYER_AND_DEPOSIT_ADDRESS);

      const solanaWallet = new SolanaWallet(web3auth!.provider!);
      // Sign the transaction
      const signedTransaction = await solanaWallet.signTransaction(transaction);
      // Serialize the transaction and send it to your backend
      const serializedTransaction = signedTransaction.serialize({ requireAllSignatures: false }).toString('base64');

      await HttpClient.post<Response<BuyCoinResponse>>('send/usdc', {
        signedTx: serializedTransaction
      })
        .then((response) => {
          checkForUSDCBalanceUpdate(500)
        })
        .catch((e) => {
          console.log("!!!!send USDC", { e });
          toast.error("Failed to withdraw USDC. Try again. (Reason: " + e.message + '"');
          setIsSendingWalletUSDCOut(false)
        })
    } catch (e: any) {
      console.error("failed to send USDC", { e })
      toast.error("Failed to send USDC. Try again. (Reason: " + e.message + '"');
      setIsSendingWalletUSDCOut(false)
    }
  }

  const buyCoinsWithUSDC = async () => {
    if (parseFloat(userBalance) < usdcAmountToBuyCoins) {
      toast.error("Not enough balance. Top up your account or lower your buy amount.");
      return
    }

    if (!usdcAmountToBuyCoins || usdcAmountToBuyCoins < 5 - USDC_RENT_AMOUNT) {
      toast.error("Buy at least $5");
      return
    }

    setIsSendingWalletETH(true)

    try {
      const senderPublicKey = new PublicKey(userAddress);
      const recipientPublicKey = new PublicKey(FEE_PAYER_AND_DEPOSIT_ADDRESS);

      // Create a new token object for USDC (replace with the correct token mint address)
      const usdcMintAddress = new PublicKey(USDCAddress);

      // Get the associated token addresses for sender and recipient
      const senderTokenAccountAddress = await getAssociatedTokenAddress(
        usdcMintAddress,
        senderPublicKey);

      const recipientTokenAccountAddress = await getAssociatedTokenAddress(
        usdcMintAddress,
        recipientPublicKey);

      // const senderAddress = await getAccount(
      //   connection,
      //   senderTokenAccountAddress,
      //   "confirmed",
      //   TOKEN_PROGRAM_ID
      // );
      // console.log("!!!!!senderAddress", senderAddress.address.toBase58())

      // const recipientAddress = await getAccount(
      //   connection,
      //   recipientTokenAccountAddress,
      //   "confirmed",
      //   TOKEN_PROGRAM_ID
      // );
      // console.log("!!!!!recipientAddress", recipientAddress.address.toBase58())

      const usdcBuyAmount = Math.floor((usdcAmountToBuyCoins) * Math.pow(10, 6))
      console.log("!!!!!", { userAddress, usdcBuyAmount })
      // Create a transaction instruction to transfer USDC
      const transferInstruction = createTransferInstruction(
        senderTokenAccountAddress,
        recipientTokenAccountAddress,
        senderPublicKey,
        usdcBuyAmount, // Assuming USDC has 6 decimal places
        [],
        TOKEN_PROGRAM_ID
      );

      // Create a new transaction
      const transaction = new Transaction().add(transferInstruction);
      transaction.add(
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: 2000000,
        })
      )
      const { blockhash } = await connection.getLatestBlockhash();
      // Set the recent blockhash and the fee payer (sender)
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = recipientPublicKey;

      const solanaWallet = new SolanaWallet(web3auth!.provider!);
      // Sign the transaction
      const signedTransaction = await solanaWallet.signTransaction(transaction);
      // Serialize the transaction and send it to your backend
      const serializedTransaction = signedTransaction.serialize({ requireAllSignatures: false }).toString('base64');

      await HttpClient.post<Response<BuyCoinResponse>>('buy/coins', {
        signedTx: serializedTransaction
      })
        .then((response) => {
          checkForCoinBalanceUpdate(500)
        })
        .catch((e) => {
          console.log("!!!!deposit", { e });
          toast.error(e.message)
          setIsSendingWalletETH(false)
        })
    } catch (e: any) {
      console.error("failed to send USDC", { e })
      toast.error("Failed to buy coins. Try again. (Reason: " + e.message + '"');
      setIsSendingWalletETH(false)
    }
  }

  const sellCoins = async () => {
    if (coinsToSell > userCoins) {
      toast.error("You don't have " + coinsToSell + " coins. Reduce sell quantity.")
      return
    }

    if (coinsToSell < 1) {
      toast.error("Min withdraw amount is 1 USD.")
      return
    }

    setIsSellingCoin(true)

    await HttpClient.post<Response<RoomsReponse>>('user/withdrawal', { amount: coinsToSell })
      .then((response) => {
        checkForCoinSellUpdate(500)
      })
      .catch((e) => {
        console.log("!!!!withdrawal", { e });
        toast.error(e)
        setIsSellingCoin(false)
      })
  }

  const checkForCoinSellUpdate = async (timeout: number) => {
    const oldBalance = parseFloat(userBalance)
    const newBalance = await getBalance(userAddress)

    if (oldBalance === parseFloat(newBalance)) {
      setTimeout(() => checkForCoinSellUpdate(timeout), timeout)
    } else {
      updateUserCoinBalance()
      getBalance(userAddress)
      setIsSellingCoin(false)
      toast.success("Withdrawal success!")
    }
  }

  const checkForUSDCBalanceUpdate = async (timeout: number) => {
    const oldBalance = parseFloat(userBalance)

    const newBalance = await getBalance(userAddress)

    if (oldBalance === parseFloat(newBalance)) {
      setTimeout(() => checkForUSDCBalanceUpdate(timeout), timeout)
    } else {
      closeWithdrawPopup()
      toast.success("Withdrawal success!")
    }
  }

  const checkForCoinBalanceUpdate = async (timeout: number) => {
    await HttpClient.get<Response<LoginResponse>>('user/coin_balance')
      .then(async (response) => {
        const data = response.data

        if (data.coins !== userCoins) {
          await getBalance(userAddress)
          setUserCoins(data.coins)
          setUserPoints(data.points)
          setIsSendingWalletETH(false)

          toast.success("Successfully bought coins!")
        } else {
          setTimeout(() => checkForCoinBalanceUpdate(timeout), timeout)
        }
      })
      .catch((e) => {
        toast.error("Failed to refresh new balance. Try again (Reason: " + e.message + '"');
      })
  }

  const showWalletPrivateKey = async () => {
    const privateKey: Maybe<string> = await new SolanaWallet(web3auth!.provider!).provider.request({
      method: "solanaPrivateKey",  // This is the method to request the private key
    });

    if (privateKey) {
      setUserPrivateKey(privateKey)
    } else {
      setUserPrivateKey("Failed to fetch secret key. Refresh the page and try again.")
    }

    setIsShowingUserSecret(true)
  }

  const hideWalletPrivateKey = () => {
    setUserPrivateKey("")
    setIsShowingUserSecret(false)
  }

  const copyLink = async (link: string) => {
    await navigator.clipboard.writeText(link)
    toast.success("Link copied!")
  }

  // const showLeaderboard = async () => {
  //   setIsShowingTutorialPopup(false)
  //   setIsShowingPointLeaderboardPopup(false)
  //   setIsShowingLeaderboardPopup(true)
  //   const response = await HttpClient.post<Response<TopUsersResponse>>('leaderboard', {})
  //   .then((response) => {
  //     const data = response.data

  //     setTopUsers(data.topUsers)
  //   })
  //   .catch((e) => {
  //     console.log("!!!!showLeaderboard failed", {e});
  //     toast.error("Failed to fetch rich list. Try again (Reason: " + e.message + '"');
  //   })
  // }

  // const closeLeaderboardPopup = () => {
  //   setIsShowingLeaderboardPopup(false)
  //   setTopUsers([])
  // }

  const showPointLeaderboard = async () => {
    // setIsShowingLeaderboardPopup(false)
    setIsShowingTutorialPopup(false)
    setIsShowingReferPopup(false)
    setIsShowingPointLeaderboardPopup(true)
    const response = await HttpClient.post<Response<TopUsersResponse>>('point_leaderboard', {})
      .then((response) => {
        const data = response.data

        setTopPointUsers(data.topUsers)
      })
      .catch((e) => {
        console.log("!!!!showPointLeaderboard failed", { e });
        toast.error("Failed to fetch point leaderboard. Try again (Reason: " + e.message + '"');
      })
  }

  const closePointLeaderboardPopup = () => {
    setIsShowingPointLeaderboardPopup(false)
    setTopPointUsers([])
  }

  const showUnresolvedBetsPopup = async () => {
    setIsShowingUnresolvedBetPopup(true)
    setIsFetchingUnresolvedBetPopup(true)
    const response = await HttpClient.post<Response<UnresolvedBets>>('bet/all_unresolved', {})
      .then((response) => {
        const unresolvedBetsData = response.data.unresolvedBets

        unresolvedBetsData.sort((betA, betB) => betB.createdAt - betA.createdAt)
        const unresolvedBetsModel: UnresolvedBet[] = []
        for (const bet of unresolvedBetsData) {
          unresolvedBetsModel.push({
            createdAt: bet.createdAt,
            title: bet.betContent,
            url: '/stream/' + bet.roomId + '?bid=' + bet.betId
          })
        }
        setIsFetchingUnresolvedBetPopup(false)
        setUnresolvedBets([...unresolvedBetsModel])
      })
      .catch((e) => {
        console.log("!!!!showUnresolvedBets failed", { e });
        toast.error("Failed to fetch unresolved bets. Try again (Reason: " + e.message + '"');
      })
  }

  const closeUnresolvedBetsPopup = () => {
    setIsShowingUnresolvedBetPopup(false)
    setIsFetchingUnresolvedBetPopup(false)
    setUnresolvedBets([])
  }

  const showTutorialPopup = () => {
    setIsShowingPointLeaderboardPopup(false)
    setIsShowingReferPopup(false)
    setIsShowingTutorialPopup(true)
  }

  const showReferPopup = () => {
    setIsShowingPointLeaderboardPopup(false)
    setIsShowingTutorialPopup(false)
    setIsShowingReferPopup(true)
  }

  const closeReferPopup = () => {
    setIsShowingReferPopup(false)
  }

  const topNavView = (
    <div className="topnav">
      <div className="topnavLeftArea">
        <div className="homeIconContainer" onClick={() => { navigate("/" + window.location.search); }}>
          <img className="navbarLogo" src="/favicon.ico"></img>
        </div>
        <div className="tutorialLinks">
          <a href="https://t.me/+Crd2IwIkQek2NzBl" target="_blank" className="navbarLinkContainer">
            [Chat]
          </a>
          <div onClick={showPointLeaderboard} className="navbarLinkContainer">
            [Rank]
          </div>
        </div>
        <div className="tutorialLinks">
          <div className="navbarLinkContainer" onClick={showTutorialPopup}>
            [Rules]
          </div>
          <div onClick={showReferPopup} className="navbarLinkContainer">
            [Refer]
          </div>
        </div>
        {
          (isCommunityMod || isResolver) && (
            <div className="tutorialLinks">
              <div className="tutorialLinks desktopTutorialLinks">
                <div onClick={showUnresolvedBetsPopup} className="navbarLinkContainer">
                  [Bets]
                </div>
              </div>
            </div>
          )
        }
      </div>
      {
        loggedIn ? (
          <div className="topnavRightArea">
            <div className="topnavCoinStarRightArea">
              <div className="navbarCoinCointainer" data-tooltip-id="navbar-points-explainer">
                <img className="navbarCoinImage" src="/point_star.png"></img>
                <div className="navbarCoinAmount">{(Math.round(userPoints * 10) / 10).toFixed(1)}</div>
                <Tooltip id="navbar-points-explainer" place="top" opacity="1" style={{ fontSize: "1rem", borderRadius: "0.8rem" }}>
                  <p>Earn 1 loyalty star for 1 USD spent.</p>
                </Tooltip>
              </div>
              <div className="navbarCoinCointainer" data-tooltip-id="navbar-coin-explainer">
                <img className="navbarCoinImage" src="/coin.png"></img>
                <div className="navbarCoinAmount">{Math.round(userCoins * 100) / 100}</div>
                <Tooltip id="navbar-coin-explainer" place="top" opacity="1" style={{ fontSize: "1rem", borderRadius: "0.8rem" }}>
                  <p>Place correct bets to earn coins.</p>
                  <p>Create bets/streams to collect coins from fees.</p>
                </Tooltip>
              </div>
            </div>
            <button onClick={() => navigate("/wallet" + window.location.search)} className="btn buy-coins-btn">
              Deposit
            </button>
          </div>
        ) : (
          <div className="topnavRightArea">
            <button onClick={login} className="btn login-btn">
              Log in
            </button>
          </div>
        )
      }
    </div>
  )

  const walletView = (
    <>
      {
        loggedIn ? (
          <div className="walletContainer">
            <div className="walletItemContainer">
              <img className="walletPageEthIcon" src="/usdcLogo.png" alt="USDC Icon" />
              <div>{Math.round(parseFloat(userBalance) * 100) / 100}</div>
            </div>
            <div className="walletItemContainer walletDepositContainer">
              <div>Send Solana mainnet USDC to {userAddress === "" ? "Loading..." : userAddress.substring(0, 5) + "..." + userAddress.substring(37)}
                <span className="walletPageAddressCopy" onClick={copyWalletAddress}>(Copy Address)</span>
              </div>
            </div>
            {
              parseFloat(userBalance) > 0 ? (
                <div className="walletActionContainer">
                  <div className="walletItemContainer walletDepositContainer logoutText" onClick={() => setIsShowingUSDCWithdrawPopup(true)}>
                    Withdraw USDC
                  </div>
                  <div className="walletItemContainer logoutText" onClick={showWalletPrivateKey}>
                    Show Private Key
                  </div>
                </div>
              ) : (
                <div className="walletActionContainer">
                  <div className="walletItemContainer walletDepositContainer logoutText" onClick={showWalletPrivateKey}>
                    Show Private Key
                  </div>
                </div>
              )
            }
            {/* {
            showOnRampSection ? (
              <iframe
                className="walletDepositContainer onramperFrame"
                src={`https://buy.onramper.com?apiKey=pk_prod_01J5RGYYESHQH1GSN1DXDG48F8&mode=buy&onlyCryptos=usdc_solana&excludePaymentMethods=creditcard&networkWallets=solana:${userAddress}`}
                title="Buy USDC"
                height={screenWidth <= 600 ? "520rem" : "630rem"}
                width={screenWidth <= 600 ? "327.6rem" : "420rem"}
                allow="accelerometer; autoplay; camera; gyroscope; payment; microphone"
              />
            ) : (
              <div className="walletItemContainer walletDepositContainer">
                <div>Or, you can directly </div>
                <div onClick={() => setShowOnRampSection(true)} className="btn buyUSDCBtn">Buy USDC</div>
              </div>
            )
          } */}

            <div className="walletItemContainer">
              <img className="walletPagePfpImage" src="./coin.png" alt="Coin" />
              <div>{Math.round(userCoins * 100) / 100}</div>
            </div>
            <div className="walletItemContainer walletDepositContainer">
              <div className="buyCoinInputContainer">
                <div className="buyCoinInputBar">
                  <span className="buyCoinDollarSign">Deposit </span>
                  <input className="buyCoinInput" value={usdcAmountToBuyCoinsString} onChange={(e) => handleCoinsToBuyChange(e.target.value)} placeholder="amount"></input>
                  <div className="maxBuyLink" onClick={updateToMaxCoinBuyAmount}>max</div>
                </div>
                <span> USDC (for {coinsAmountToBuy} USD)</span>
                <button onClick={buyCoinsWithUSDC} className="btn buy-coin-btn">
                  {isSendingWalletETH ? "Depositing..." : "Deposit USDC to App"}
                </button>
              </div>
            </div>
            {
              userCoins > 0 && (
                <div className="walletItemContainer walletDepositContainer">
                  <div className="buyCoinInputContainer">
                    <span>Withdraw </span>
                    <div className="buyCoinInputBar">
                      <input className="buyCoinInput" value={coinsToSell} onChange={(e) => handleCoinsToSellChange(Number(e.target.value))} placeholder="amount"></input>
                      <div className="maxBuyLink" onClick={(e) => handleCoinsToSellChange(userCoins)}>max</div>
                    </div>
                    <span> USD (into {ethSellFunded} USDC)</span>
                    <button onClick={sellCoins} className="btn sell-coin-btn">
                      {isSellingCoin ? "Withdrawing..." : "Withdraw USDC from App"}
                    </button>
                  </div>
                </div>
              )
            }
            <div className="walletItemContainer">
              <img className="walletPagePfpImage walletPagePointImage" src="./point_star.png" alt="Points" />
              <div className="walletPageDisplayName">{(Math.round(userPoints * 10) / 10).toFixed(1)}</div>
            </div>

            <div className="walletItemContainer">
              <img className="walletPagePfpImage walletPagePointImage" src="./twitch.png" alt="Twitch" />
              <div className="walletPageDisplayName"><TwitchLinkBtn fromWallet={true} /></div>
            </div>

            <div className="walletItemContainer">
              <img className="walletPagePfpImage walletPagePointImage" src="./youtube.jpg" alt="Youtube" />
              <div className="walletPageDisplayName"><YoutubeLinkBtn fromWallet={true} /></div>
            </div>

            <div className="walletItemContainer">
              <img className="walletPagePfpImage" src={remotePfpUrl} alt="Profile Picture" />
              <div className="walletPageDisplayName">{displayName}</div>
              <div className="logoutContainer" onClick={() => setIsShowingDisplayNamePopup(true)}>
                <img className="editDisplayNameButton" src="/edit_button.png"></img>
              </div>
            </div>
            <div className="walletItemContainer">
              <div className="logoutContainer" onClick={logout}>
                <div className="logoutText">Log Out</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="walletContainer">You need to log in first</div>
        )
      }
    </>
  )

  const appView = (
    <div>
      {
        (streamCreationMode !== StreamCreationMode.CLOSED) && (
          <>
            <div className="overlay" onClick={abortingCreatingStream} />
            <div className={(streamCreationMode === StreamCreationMode.FUTURE) ? "createFeaturedStreamContainer" : "createStreamContainer"} ref={createStreamContainerRef}>
              <div className="createStreamHeader">
                <img onClick={abortingCreatingStream} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="createStreamHeaderTab">
                <div className={(streamCreationMode === StreamCreationMode.IN_APP) ? "createStreamHeaderTabSelectedItem" : "createStreamHeaderTabItem"}
                  onClick={() => { setStreamCreationMode(StreamCreationMode.IN_APP) }}>In App Stream</div>
                <div className={(streamCreationMode === StreamCreationMode.EXTERNAL_LINK) ? "createStreamHeaderTabSelectedItem" : "createStreamHeaderTabItem"}
                  onClick={() => { setStreamCreationMode(StreamCreationMode.EXTERNAL_LINK) }}>External Stream</div>
                <div className={(streamCreationMode === StreamCreationMode.FUTURE) ? "createStreamHeaderTabSelectedItem" : "createStreamHeaderTabItem"}
                  onClick={() => { setStreamCreationMode(StreamCreationMode.FUTURE) }}>Future Stream</div>
              </div>
              {
                (streamCreationMode === StreamCreationMode.IN_APP) && (
                  <>
                    <div className="streamUserNameContainer">
                      <div>Enter Stream Title</div>
                      <input value={inCreateInAppStreamTitle} onChange={e => setInCreateInAppStreamTitle(e.target.value)} className="inCreateStreamerUserNameInput" placeholder="The ThreadGuy Interview"></input>
                    </div>
                  </>
                )
              }
              {
                (streamCreationMode === StreamCreationMode.FUTURE) && (
                  <>
                    <div>Enter Stream Title</div>
                    <input value={inCreateFeaturedStreamTitle} onChange={e => setInCreateFeaturedStreamTitle(e.target.value)} className="inCreateStreamerUserNameInput" placeholder="Jerome Powell Speech"></input>
                    <div className="inCreateStreamStartTimeContainer">
                      <div className="inCreateStreamStartTimeItem">
                        <div>Year</div>
                        <input value={inCreateFeaturedStreamYear} onChange={e => setInCreateFeaturedStreamYear(parseInt(e.target.value))} className="inCreateStreamerYearInput" placeholder="Year"></input>
                      </div>
                      <div className="inCreateStreamStartTimeItem">
                        <div>Month</div>
                        <input value={inCreateFeaturedStreamMonth} onChange={e => setInCreateFeaturedStreamMonth(parseInt(e.target.value))} className="inCreateStreamerMonthInput" placeholder="Month"></input>
                      </div>
                      <div className="inCreateStreamStartTimeItem">
                        <div>Day</div>
                        <input value={inCreateFeaturedStreamDay} onChange={e => setInCreateFeaturedStreamDay(parseInt(e.target.value))} className="inCreateStreamerDayInput" placeholder="Day"></input>
                      </div>
                      <div className="inCreateStreamStartTimeItem">
                        <div>Hour</div>
                        <input value={inCreateFeaturedStreamHour} onChange={e => setInCreateFeaturedStreamHour(parseInt(e.target.value))} className="inCreateStreamerHourInput" placeholder="Hour"></input>
                      </div>
                      <div className="inCreateStreamStartTimeItem">
                        <div>Minute</div>
                        <input value={inCreateFeaturedStreamMinute} onChange={e => setInCreateFeaturedStreamMinute(parseInt(e.target.value))} className="inCreateStreamerMinuteInput" placeholder="Minute"></input>
                      </div>
                    </div>
                    <div className="selectTime">
                      <span>In: </span>
                      <label>
                        <input type="radio" name="timeZone" value="local" checked={timeZone === 'local'} onChange={e => setTimeZone(e.target.value as TimeZone)} />
                        Local Time
                      </label>
                      <label>
                        <input type="radio" name="timeZone" value="utc" checked={timeZone === 'utc'} onChange={e => setTimeZone(e.target.value as TimeZone)} />
                        UTC Time
                      </label>
                    </div>
                  </>
                )
              }
              {
                (streamCreationMode === StreamCreationMode.EXTERNAL_LINK) && (
                  <>
                    <div className="streamUserNameContainer">
                      <div>Enter Stream Link</div>
                      <div className="streamPlatformHint">(supports Youtube, Twitch, Twitter, Kick and streamed.su streams)</div>
                      <input value={inCreateFeaturedStreamUrl} onChange={e => setInCreateFeaturedStreamUrl(e.target.value)} className="inCreateStreamerUserNameInput" placeholder="https://www.youtube.com/watch?v=Z8UTqxU3Cdo"></input>
                    </div>
                  </>
                )
              }
              <div className="createStreamFooter">
                <button className="btn createStreamBtn" onClick={createStreamOnBackend}>Create Stream</button>
                <div className="streamFeesHint">* {(streamCreationCost === 0) ? "Free to create. " : `Costs ${streamCreationCost} USD. `}Earn up to {betStreamCreatorOrCommentatorTakeRate * 100}% bet fees and {decisionCommentatorTakeRate * 100}% of decision fees of this stream</div>
              </div>
            </div>
          </>
        )
      }
      {
        isShowingTutorialPopup && (
          <>
            <div className="overlay" onClick={() => setIsShowingTutorialPopup(false)} />
            <div className="tutorialContainer">
              <div className="createStreamHeader">
                <img onClick={() => setIsShowingTutorialPopup(false)} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="tutorialHowToPlay">
                How it Works
              </div>
              {/* <div className="tutorialContentHeader">
                Bet 
              </div> */}
              <div className="tutorialContentBody">
                <div>1. Create or place bets on livestreams.</div>
                <div>2. When you bet, you earn tickets. Lower odds = more tickets.</div>
                <div>3. Payout = Total Wadgered of the Bet × Your Tickets on Winning Option / Total Winning Tickets.</div>
                <div>4. The team resolves all bets.</div>
                <div>5. Deposit or withdraw anytime on the "Deposit" page.</div>
              </div>
            </div>
          </>
        )
      }
      {
        isShowingDisplayNamePopup && (
          <>
            <div className="overlay" onClick={closeUpdateProfileInfoPopup} />
            <div className="updateDisplayNameContainer popup">
              <div className="createStreamHeader">
                <img onClick={closeUpdateProfileInfoPopup} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="pfpContainer">
                <label htmlFor="pfpClicker">
                  <img className="pfpImage" src={profilePicUrl} alt="Profile Picture" />
                  <div className="uploadPfpOverlay">
                    <img className="uploadPfp" src="/upload_pfp.png" alt="Upload Profile Picture" />
                  </div>
                </label>
                <input id="pfpClicker" className="pfpUploadIcon" type="file" accept="image/*" onChange={onPfpUpdated}></input>
              </div>
              <div className="streamUserNameContainer">
                <input value={newDisplayName} onChange={e => setNewDisplayName(e.target.value)} className="inCreateStreamerUserNameInput" placeholder={displayName}></input>
              </div>
              <button className="btn create-bet-btn updateDisplayNameButton" onClick={updateDisplayNameAndPfpOnBackend}>Update</button>
            </div>
          </>
        )
      }
      {
        isShowingReferPopup && (
          <>
            <div className="overlay" onClick={closeReferPopup} />
            <div className="tutorialContainer">
              <div className="createStreamHeader">
                <img onClick={closeReferPopup} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="refererIntroText">You earn 1 loyalty star for every 1 USD your referee spent.</div>
              <div className="refererIntroText">Invite more friends, so you earn more stars.</div>
              <div className="refererIntroText">Your referee gets 10 loyalty stars after signing up with your referral link.</div>
              {
                isUserLoggedIn() ? (
                  <>
                    <div className="shareUrlContainer">
                      <div className="shareUrlText">{window.location.protocol + '//' + window.location.host + '?referer=' + userAddress}</div>
                      <div className="btn shareUrlContainerCopyButton"
                        onClick={() => copyLink(window.location.protocol + '//' + window.location.host + '?referer=' + userAddress)}>Copy</div>
                    </div>
                    <div className="refererFooterContainer">
                      <div>Your referral stars earned:</div>
                      <img className="referralCoinImage" src="/point_star.png"></img>
                      <div>0</div>
                    </div>
                  </>
                ) : (
                  <div className="refererIntroText">
                    (After you sign up you will see your referral link.)
                  </div>
                )
              }
            </div>
          </>
        )
      }
      {
        isShowingPointLeaderboardPopup && (
          <>
            <div className="overlay" onClick={closePointLeaderboardPopup} />
            <div className="tutorialContainer">
              <div className="createStreamHeader">
                <img onClick={closePointLeaderboardPopup} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="tutorialHowToPlay">
                Leaderboard
              </div>
              <div className="tutorialContentBody">
                {
                  (!topPointUsers || topPointUsers.length === 0) ? (
                    <div className="leaderboardLoading">Loading...</div>
                  ) : (
                    <div>
                      {
                        topPointUsers.map((user, index) => (
                          <div className="leaderboardItem">
                            <div>{(index + 1) + ". " + user.displayName + "(" + user.userId.substring(0, 2) + "..." + user.userId.substring(39) + ")"}</div>
                            <div className="leaderboardCoinContainer">
                              <img className="leaderboardCoinImage" src="/point_star.png"></img>
                              <div>{user.points}</div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  )
                }
              </div>
            </div>
          </>
        )
      }
      {
        isShowingUnresolvedBetPopup && (
          <>
            <div className="overlay" onClick={closeUnresolvedBetsPopup} />
            <div className="tutorialContainer">
              <div className="createStreamHeader">
                <img onClick={closeUnresolvedBetsPopup} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="tutorialHowToPlay">
                Unresolved Bets
              </div>
              <div className="tutorialContentBody">
                {
                  (isFetchingUnresolvedBetPopup) ? (
                    <div>Loading...</div>
                  ) : (
                    (unresolvedBets && unresolvedBets.length > 0) ? (
                      unresolvedBets?.map((bet) => (
                        <div className="leaderboardItem">
                          <a href={bet.url}>{bet.title}</a>
                        </div>
                      ))
                    ) : (
                      <div>All bets are resolved. Yay!</div>
                    )
                  )
                }
              </div>
            </div>
          </>
        )
      }
      {
        isShowingDepositPopup && <DepositPopup
          userBalance={userBalance}
          closeDepositPopup={closeDepositPopup}
          isSendingWalletETH={isSendingWalletETH}
          updateToMaxCoinBuyAmount={updateToMaxCoinBuyAmount}
          usdcAmountToBuyCoinsString={usdcAmountToBuyCoinsString}
          handleCoinsToBuyChange={handleCoinsToBuyChange}
          buyCoinsWithUSDC={buyCoinsWithUSDC}
        />
      }
      {
        isShowingUSDCWithdrawPopup && (
          <>
            <div className="overlay" onClick={closeWithdrawPopup} />
            <div className="withdrawUSDCPopupContainer popup" ref={createStreamContainerRef}>
              <div className="createStreamHeader">
                <img onClick={closeWithdrawPopup} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="streamUserNameContainer">
                <div className="streamUserNameTitle">Withdraw</div>
                <div>Amount: <input className="withdrawPopupInput" value={walletPageSendAmount} onChange={e => setWalletPageSendAmount(Number(e.target.value))} type="number" placeholder="amount"></input></div>
                <div>Address: <input className="withdrawPopupInput" value={walletPageSendAddress} onChange={e => setWalletPageSendAddress(e.target.value)} type="string" placeholder="address"></input></div>
              </div>
              <button disabled={isSendingWalletUSDCOut} className="btn create-bet-btn updateDisplayNameButton" onClick={sendUSDCBalanceOut}>
                {isSendingWalletUSDCOut ? "Sending..." : "Send"}
              </button>
            </div>
          </>
        )
      }
      {
        isShowingUserSecret && (
          <>
            <div className="overlay" onClick={hideWalletPrivateKey} />
            <div className="secretKeyPopupContainer popup" ref={createStreamContainerRef}>
              <div className="createStreamHeader">
                <img onClick={hideWalletPrivateKey} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="secretKeyContainer">
                {userPrivateKey}
              </div>
              <div className="secretKeyContainerFooter">
                *Never show secret to anyone else.
              </div>
            </div>
          </>
        )
      }
      {topNavView}
      <Routes>
        <Route path="/" element={landingView} />
        <Route path={"/wallet"} element={walletView} />
        <Route path="/twitch/callback" element={TwitchCallback} />
        <Route path="/youtube/callback" element={YoutubeCallback} />
        <Route path="/stream/:id" element={<StreamView zim={zim} isIMLoggedIn={isIMLoggedIn}
          handlePaymentError={handlePaymentError}
          web3auth={web3auth}
          hasInteracted={hasInteracted}
          zegoExpressEngine={zegoExpressEngine} zimToken={zimToken}
          userCoins={userCoins} setUserCoins={setUserCoins} setUserPoints={setUserPoints} displayName={displayName}
          profilePicUrl={remotePfpUrl} login={login} userAddress={userAddress} guestId={guestId}
          isCommunityMod={isCommunityMod}
          isResolver={isResolver} betCreationTakeRate={betCreationTakeRate} betCreationCost={betCreationCost}
          overallTakeRate={overallTakeRate}
          overallDecisionTakeRate={overallDecisionTakeRate}
          decisionCommentatorTakeRate={decisionCommentatorTakeRate}
          giftPrice={giftPrice}
          updateUserCoinBalance={updateUserCoinBalance}
          mixpanel={mixpanel}
          connection={connection}
          screenWidth={screenWidth} />} />
        <Route path="*" element={landingView} />
      </Routes>
      <ToastContainer position="top-center" autoClose={2500} />
    </div>
  );
  return (isOnMobile() && !isPwa()) ? askPwaView() : appView
}

export default function Root() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
