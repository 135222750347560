export type Response<T> = {
    data: T
}

export type LoginResponse = {
    zimToken: string,
    userId: string,
    coins: number,
    points: number,
    depositedUSDC: number,
    usdcDepositCap: number,
    address: string,
    refererId?: string,
    displayName?: string,
    pushToken?: string,
    profilePicUrl?: string,
    isResolver?: boolean,
    isCommunityMod: boolean,
    streamCreationCost: number,
    giftPrice: { [key: string]: number },
    betCreationCost: number,
    betCreationTakeRate: number,
    betStreamCreatorOrCommentatorTakeRate: number,
    decisionCommentatorTakeRate: number,
    overallTakeRate: number,
    overallDecisionTakeRate: number
    twitchStreamApiToken?: string
    youtubeStreamApiToken?: string
}

export type ZIMTokenResponse = {
    zimToken: string
}

export type BuyCoinResponse = {
    message?: string,
    errorMessage?: string
}

export type ClaimAirdropResponse = {
    message?: string,
    errorMessage?: string
}

export type RoomsReponse = {
    rooms: TopRoomResponse[],
    topRoom?: TopRoomResponse
}

export type RoomsReponseV2 = {
    rooms: TopRoomResponse[],
    pastRooms: TopRoomResponse[]
}

export type TopUsersResponse = {
    topUsers: TopUserResponse[]
}

export type TopUserResponse = {
    userId: string,
    displayName?: string,
    coins: number,
    points: number
}

export type PresignUrlResponse = {
    upload_url: string,
}

export type UnresolvedBets = {
    unresolvedBets: BetResponse[]
}

export type BetResponse = {
    betId: string,
    betContent: string,
    roomId: string,
    optionDisplayNames: string[],
    optionTVL: number[],
    optionShares: number[],
    resolvedOption: number,
    stoppedTakingBets?: boolean,
    expireTimestamp?: number,
    createdAt: number,
    type: number,
    userBettedOptions: number[],
    takeRate: number
}

export type TopRoomResponse = {
    roomId: string,
    bettedTVL: number,
    newStreamId?: string,
    isStreamOver?: boolean,
    streamStartTime?: number,
    createdAt?: number,
    streamTitle?: string,
    commentatorId?: string,
    isStreamingNatively?: boolean,
    streamerProfilePicUrl?: string,
    streamerDisplayName?: string,
    streamPreviewThumbnail?: string
}

export type UpdateRoomLivenessResponse = {
    room: RoomResponse
}

export type UpdateRoomCommentatorIdResponse = {
    commentatorId: string,
    commentatorDisplayName: string,
    commentatorProfilePicUrl: string,
    errorMessage?: string
}

export type RoomResponse = {
    roomId: string,
    creatorId: string,
    newStreamId?: string,
    streamTitle?: string,
    commentatorId?: string,
    commentatorDisplayName?: string,
    commentatorProfilePicUrl?: string,
    creatorDisplayName?: string,
    creatorProfilePicUrl?: string,
    userUsdEarnings: number,
    streamerCoinTips: { [key: string]: string },
    bets: BetResponse[],
    trendingBet: BetResponse,
    bettedTVL: number,
    isStreamOver?: boolean,
    streamStartTime?: number,
    topComment?: BetResponse,
    isStreamingNatively?: boolean,
    streamerProfilePicUrl?: string,
    streamerDisplayName?: string
}

export type CreateBetOrStreamResponse = {
    roomId: string,
    newStreamId?: string,
    bets: BetResponse[],
    trendingBet: BetResponse,
    userCoins: number,
    userPoints: number
}

export type UpdateStreamResponse = {
    newStreamId?: string,
}

export type PlaceBetResponse = {
    userBalance: number,
    userPoints: number,
    bet: BetResponse,
}

export type UpdateUserDisplayNameResponse = {
    displayName: string
}

export type IMMessage = {
    text: string,
    // 0 - text
    messageType: number,
}

export type MessageBody = {
    messageType: MessageBodyType,
    betId?: string,
    userDisplayName?: string,
    recipientId?: string,
    claimedUserAddress?: string[],
    userPfpUrl?: string,
    text?: string,
    type?: string,
    subtype?: string,
    betterId?: string,
    amount?: number,
    airdropId?: string,
    expirationTimestamp?: number,
    optionIndex?: number,
    color?: string,
    senderLiveBetId?: string
    audioUrl?: string
}

export enum MessageBodyType {
    ADD_BET = "ADD_BET",
    RESOLVED_BET = "RESOLVED_BET",
    PLACED_BET = "PLACED_BET",
    CHAT_MESSAGE = "CHAT_MESSAGE",
    TRENDING_BET = "TRENDING_BET",
    UPDATE_STREAM_LINK = "UPDATE_STREAM_LINK",
    STOP_BET = "STOP_BET",
    SEND_GIFT = "SEND_GIFT",
    SEND_SUPER_CHAT = "SEND_SUPER_CHAT",
    UPDATE_COMMENTATOR = "UPDATE_COMMENTATOR",
    ADD_TOP_COMMENT = "ADD_TOP_COMMENT",
    PLACED_TOP_COMMENT_VOTE = "PLACED_TOP_COMMENT_VOTE",
    RESOLVED_TOP_COMMENT = "RESOLVED_TOP_COMMENT",
    CREATE_AIRDROP = "CREATE_AIRDROP",
    CLAIM_AIRDROP = "CLAIM_AIRDROP",
    RESOLVE_AIRDROP = "RESOLVE_AIRDROP",
    COMMENTATOR_AUDIO_MESSAGE = "COMMENTATOR_AUDIO_MESSAGE"
}

export type ChatMessage = {
    messageId: string,
    type: MessageBodyType,
    text: string,
    userDisplayName: string,
    userPfpUrl?: string,
    airdropId?: string,
    expirationTimestamp?: number,
    airdropClaimStatus?: AirdropClaimStatus,
    betId?: string,
    color?: string,
    senderLiveBetId?: string
    audioUrl?: string
}

export enum AirdropClaimStatus {
    UNCLAIMED = "unclaimed",
    CLAIMED = "claimed",
    EXPIRED = "expired",
}

export enum SelectedChannel {
    YOUTUBE = "youtube",
    KICK = "kick",
    TWITCH = "twitch",
    TWITTER = "twitter",
    STREAMED = "streamed",
    FEATURED = "featured",
    IN_APP = "inapp",
    UNKNOWN = "unknown",
}