import { useEffect, useRef, useState } from 'react'
import { ChatMessage } from '../network/types'
import { trackLog } from '../utils/utils'
import './superChat.css'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


export type SuperChatMessage = ChatMessage & {
    createdAt: number
}

export function SuperChatContainer(props: { messages: SuperChatMessage[] }) {
    let total = props.messages.length
    if (total > 0) {
        const message = props.messages[0]
        return <div className="superChatContainer">
            <SuperChat message={message}/>
        </div>
    }
    return null
}

function SuperChat(props: { message: SuperChatMessage }) {
    const { message } = props
    const [percentage, setPercentage] = useState(0)
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const duration = 10_000;
    const interval = 100;
    const increment = 100 / (duration / interval);
    // play audio
    useEffect(() => {
        if (!message.audioUrl) return;
        trackLog('start playing ', message.audioUrl)

        setPercentage(0)
        const timer = setInterval(() => {
            setPercentage((prev) => {
                if (prev + increment >= 100) {
                    clearInterval(timer)
                    return 100;
                }
                return prev + increment
            });
        }, interval)

        if (message.audioUrl) {
            trackLog('start playing ', message.audioUrl)

            if (audioRef.current) {
                audioRef.current.pause()
                audioRef.current.currentTime = 0
            }

            const audio = new Audio(message.audioUrl)
            audioRef.current = audio;
            audio.play().catch((error) => {
                trackLog("failed play video", error)
            });
        }

        return () => {
            clearInterval(timer)
            if (audioRef.current) {
                audioRef.current.pause()
                audioRef.current.currentTime = 0
                audioRef.current = null
            }
        };
    }, [message.audioUrl])

    return <div className='superChat'>
        <div className='superChatHead'>
            <div className='superChatUser'>
                <div><img src={message.userPfpUrl} /></div>
                <div>{message.userDisplayName}</div>
            </div>
            <div style={{ width: 20, height: 20 }}>
                <CircularProgressbar
                    className="question-countdown"
                    styles={buildStyles({
                        textSize: "6vh",
                        textColor: "#000000",
                        pathColor: "#000000",
                    })}
                    value={percentage} />
            </div>
        </div>
        <div className='superChatText'>{message.text}</div>
    </div>
}
