import { useNavigate } from "react-router-dom";
import { isOnMobile, trackLog } from "../utils/utils";
import { useEffect, useState } from "react";
import { HttpClient } from "../network";
import { UpdateStreamResponse } from "../network/types";
import { toast } from "react-toastify";
import { Response } from "../network/types";
import { handleRequestYoutubeToken } from "./youtube";

const clientId = 'r42b4nqiqf8uq909a24g3ikg3wpjd0'
const redirectPath = '/twitch/callback'
const scope = "channel:read:stream_key";
const rtmpBaseUrl = 'rtmp://sfo.contribute.live-video.net/app/'

export function twitchRtmpAddress() {
    return rtmpBaseUrl + localStorage.getItem('twitchStreamApiToken')
}

function handleRequestTwitchToken() {
    localStorage.setItem('lastPath', window.location.pathname)
    const authUrl = `https://id.twitch.tv/oauth2/authorize?scope=${encodeURIComponent(scope)}&response_type=token&client_id=${clientId}&redirect_uri=${encodeURIComponent(window.location.origin + redirectPath)}`
    trackLog('authUrl', authUrl)
    window.location.href = authUrl
}

function TwitchRedirecting() {
    const [status, setStatue] = useState('Parsing access_token')
    const navigate = useNavigate()
    useEffect(() => {
        const hash = window.location.hash.substring(1);
        const params = new URLSearchParams(hash);
        const access_token = params.get('access_token');
        trackLog('access_token', access_token)

        if (typeof access_token != 'string' || access_token.length < 5) {
            trackLog('no access_token')
            setStatue('no access_token')
            toast.error("not valid access_token");
            return
        }
        setStatue('Verifying token')
        const verify_token = async () => {
            trackLog('verifying token')
            try {
                let response = await HttpClient.post<Response<any>>('user/update_twitch_api_token', {
                    twitchOauthToken: access_token
                })

                setStatue('Verified! Redirecting..')
                localStorage.setItem('twitchStreamApiToken', response.data.twitchStreamApiToken)
                trackLog('twitchStreamApiToken', response.data.twitchStreamApiToken)
                let lastPath = localStorage.getItem('lastPath')
                if (lastPath) {
                    localStorage.removeItem('lastPath')
                    navigate(lastPath ?? '/')
                    // setTimeout(() => {
                    //     navigate(lastPath ?? '/')
                    // }, 1000);
                } else {
                    navigate('/')
                }
            } catch (e: any) {
                toast.error(e.message ?? "Failed to link Twitch. Try again");
            }
        }
        verify_token()
    }, [])

    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="spinner"></div>
        <div style={{ fontSize: '1.2rem' }}>{status}</div>
    </div>
}


export function TwitchLinkBtn(props: { fromWallet?: boolean }) {
    const [isLinked, setIsLinked] = useState(localStorage.getItem('twitchStreamApiToken') ? true : false)
    const [isSending, setIsSending] = useState(false)
    const handleUnlink = async () => {
        setIsSending(true)
        try {
            let response = await HttpClient.post<Response<any>>('user/update_twitch_api_token', {
                twitchOauthToken: ''
            })
            if (response?.data?.twitchStreamApiToken) {
                toast.error("Failed to unlink Twitch")
                return
            }
            localStorage.removeItem('twitchStreamApiToken')
            setIsLinked(false)
        } catch (e: any) {
            trackLog('unlink err', e)
            toast.error(e.message ?? "Failed to unlink Twitch. Try again");
        }
        setIsSending(false)
    }
    if (isLinked) {
        if (props.fromWallet) {
            return <div>You will stream here and Twitch simultanously. (
                {isSending ? <span>Unlinking..</span> : <span onClick={handleUnlink} style={{ cursor: 'pointer', color: 'rgb(0, 186, 250)', textDecoration: 'underline' }}>Unlink Twitch</span>}
                )</div>
        }
        return <></>

    }
    return <div> {isOnMobile() ? 'Stream to Twitch simultanously? ' : 'Want to stream here and Twitch simultanously? '}(
        <span onClick={() => handleRequestTwitchToken()} style={{ cursor: 'pointer', color: 'rgb(0, 186, 250)', textDecoration: 'underline' }}>Link Twitch</span>
        )</div>
}

export function SocialLinkBtns() {
    if (localStorage.getItem('twitchStreamApiToken') && localStorage.getItem('youtubeStreamApiToken')) {
        return <></>
    }
    return <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap', margin: '0 10px' }}>
        <div>Want to stream on Twitch/Youtube simultanously?</div>

        <div style={{ display: 'flex', gap: '5px' }}>
            {!localStorage.getItem('twitchStreamApiToken') && <div>(<span onClick={() => handleRequestTwitchToken()} style={{ cursor: 'pointer', color: 'rgb(0, 186, 250)', textDecoration: 'underline' }}>Link Twitch</span>)</div>}

            {!localStorage.getItem('youtubeStreamApiToken') && <div>(<span onClick={() => handleRequestYoutubeToken()} style={{ cursor: 'pointer', color: 'rgb(0, 186, 250)', textDecoration: 'underline' }}>Link Youtube</span>)</div>}
        </div>

    </div>
}

export const TwitchCallback = <div style={{ height: 600, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
    <TwitchRedirecting />
</div>