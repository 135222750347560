
export const DepositPopup = (props: any) => {
    const {
        userBalance,
        closeDepositPopup,
        isSendingWalletETH,
        updateToMaxCoinBuyAmount,
        usdcAmountToBuyCoinsString,
        handleCoinsToBuyChange,
        buyCoinsWithUSDC
    } = props
    return <>
        <div className="overlay" onClick={closeDepositPopup}></div>
        <div className=" popup">
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: '0.9rem' }}>Top up your account balance</div>
                    <img onClick={closeDepositPopup} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ flex: 3 }}><input value={usdcAmountToBuyCoinsString} style={{ width: '90%', fontSize: '1.7rem', padding: '5px 0 5px 10px' }}
                        onChange={(e) => handleCoinsToBuyChange(e.target.value)}
                        placeholder="0" /></div>

                    <div style={{ flex: 1, display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center', cursor: 'pointer', border: '1px solid var(--color-border)', borderRadius: '20px', padding: '5px ', fontSize: '0.8em' }} className='walletSelectionBtn'>
                        <img src="/usdcLogo.png" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                        <div>USDC</div>

                    </div>
                </div>

                <div style={{ fontSize: '0.9rem', display: 'flex', justifyContent: 'flex-end', gap: 5 }}>
                    <div style={{ color: 'var(--color-light-2)' }}>{Math.round(parseFloat(userBalance) * 100) / 100} USDC</div>
                    <div style={{ color: 'var(--color-btn)', cursor: 'pointer' }} onClick={updateToMaxCoinBuyAmount}>Max</div>
                </div>
                <div className="btn" style={{ display: 'flex', justifyContent: 'center', fontSize: '1.2rem', padding: '0.8rem', marginTop: '20px' }} onClick={buyCoinsWithUSDC}>{isSendingWalletETH ? "Depositting..." : "Deposit"}</div>
            </div>

        </div>
    </>
}